<template>
  <div class="my-2 py-2">
    <ion-row>
      <ion-col size="2" class="pl-0">
        <div class="image-container">
          <td-image
            class="product-image"
            :image="item.image"
            :imagePath="IMAGE_PATH.PRODUCT"
            :imageDefault="DEFAULT_PRODUCT_IMAGE"
          ></td-image>
          <div v-if="item.halal" class="halal-label">{{ $t('halal') }}</div>
        </div>
      </ion-col>

      <ion-col size="10" class="pl-3 pr-0">
        <ion-label class="fw-400 name fs-3" v-if="item.is_sample">
          {{ `${$t('sample_product')} ${item.product_name}` }}
        </ion-label>
        <ion-label class="fw-400 name fs-3" v-else>
          {{ item.product_name }}
        </ion-label>
        <ion-row class="mt-1 fs-12 fw-normal justify-space-between">
          <ion-col size="auto" class="px-0 sku-title">
            <ion-label color="grey">
              {{ formatSkuName }}
            </ion-label>
          </ion-col>
          <ion-col align="right" size="auto" class="px-0 sku-desc">
            <ion-label class="" color="grey">
              {{ showQuantity }}
            </ion-label>
          </ion-col>
        </ion-row>
        <ion-row>
          <tooltip v-if="item.is_catch_weight" />
        </ion-row>
        <ion-row>
          <ion-col size="10" class="px-0">
            <ion-label>
              <ion-text class="fs-3 fw-bold" color="primary">
                {{ price }}
              </ion-text>
              <ion-text v-if="item.price_is_tax_inclusive" style="font-style: italic">
                ({{ $t('tax_included') }})
              </ion-text>
            </ion-label>
          </ion-col>
          <ion-col align="right" size="2" class="px-0">
            <ion-label class="fw-500" color="dark"> {{ item.order_qty }}x </ion-label>
          </ion-col>
        </ion-row>
        <ion-row v-if="item.is_dropship">
          <ion-col size="12" class="d-flex align-center px-0 py-0">
            <div class="dummy-spacing mr-1"></div>
            <ion-text color="danger">
              {{ $t('not_eligible_for_self_pickup') }}
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </div>
</template>
<script>
import Tooltip from '@/components/molecules/tooltip/Tooltip.vue';
import { DEFAULT_PRODUCT_IMAGE, IMAGE_PATH } from '@/modules/sale/constants/index';
import { displayPrice } from '@/modules/sale/services/libs/helper';
import { priceFormatter } from '@/utils/';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  components: {
    Tooltip
  },
  setup() {
    const user = ref(null);

    return {
      user,
      priceFormatter,
      IMAGE_PATH,
      DEFAULT_PRODUCT_IMAGE
    };
  },

  inject: ['$storage'],

  async mounted() {
    this.user = await this.$storage.getUser();
  },

  computed: {
    showQuantity() {
      if (this.item.is_order_by_weight) {
        return `${Number(this.item.order_weight)} KG(s)`;
      }
      return `${this.item.order_qty} ${this.item.oom}(s)`;
    },
    currencySymbol() {
      return this.user && this.user.currency_symbol;
    },
    price() {
      const price = !this.item.is_order_by_weight
        ? displayPrice(this.item.price * this.item.order_qty)
        : displayPrice((this.item.price / this.item.weight) * Number(this.item.order_weight));
      return priceFormatter(this.currencySymbol, price);
    },
    formatSkuName() {
      //remove product name and use this regex "/^[\s,]+/" to clear space and comma before sku name
      return this.item.sku.replace(this.item.product_name, '').replace(/^[\s,]+/, '');
    }
  }
});
</script>
<style lang="scss" scoped>
ion-label {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &.name {
    white-space: pre-wrap;
    line-height: 20px;
  }
}

.sku-title {
  min-width: 0;
  flex-grow: 1 !important;
  flex-basis: 0% !important;
}

.sku-desc {
  margin-left: 0.5rem;
  text-align: right;
}

.not-available {
  background-color: #e0e0e0 !important;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-top: 0.5rem;
  padding: 0 1rem;
}
.image-container {
  position: relative;
}
.halal-label {
  position: absolute;
  display: flex;
  width: 100%;
  padding: 0.915px 1.831px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -12px;
  background: var(--success-500, #12b76a);
  font-size: 10px;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.229px;
  color: #ffffff;
  z-index: 1;
}
</style>
